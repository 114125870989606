<template>
    <div>
        <div class="d-flex flex-column">
            <div
                id="title-general"
                class="d-flex flex-column flex-md-row justify-content-between align-items-center"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <router-link
                        to="/app/images"
                        class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                    >
                        <span>🏠</span>
                    </router-link>
                    <h1 class="h2">
                        &nbsp;Detalle de imagen
                        <span class="text-primary"> #{{ id }} </span>
                    </h1>
                </div>

                <div class="d-flex align-items-center">
                    <vs-tooltip>
                        <vs-button icon border @click="copyTo"> 📑 </vs-button>
                        <template #tooltip>
                            <div class="content-tooltip">
                                <span> Copiar en portapapeles </span>
                            </div>
                        </template>
                    </vs-tooltip>
                    <vs-switch
                        v-if="$ability.hasScope('image:Update')"
                        v-model="enabledUpdate"
                        :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                    >
                        🖊
                    </vs-switch>
                </div>
            </div>
            <span>
                Puedes cambiar unicamente el campo alt de la imagen. Este campo
                ayuda al posicionamiento de las imágenes.
            </span>
        </div>
        <div v-if="loadedAllData" id="image-features">
            <div class="d-flex flex-wrap">
                <div
                    v-if="id"
                    class="col-12 mt-3 p-0 d-flex justify-content-around"
                >
                    <small class="text-left">
                        📆💾 {{ imageComponent.createdAt | date }}
                    </small>
                    <small class="text-left">
                        📆✏ {{ imageComponent.updatedAt | date }}
                    </small>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div
                    v-if="id"
                    class="col-12 mt-3 p-0 d-flex justify-content-around"
                >
                    <small class="text-left">
                        Tipo de imagen
                        <span class="text-primary">
                            {{ imageComponent.size }}
                        </span>
                    </small>
                    <small
                        v-if="imageComponent.uploaded"
                        class="text-left text-success"
                    >
                        Carga con éxito
                    </small>
                    <small v-else class="text-left text-warning">
                        Error en la carga
                    </small>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="imageComponent.url"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Url"
                        class="opacity-false"
                        disabled
                    />
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="imageComponent.alt"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Slug"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                    />
                </div>
            </div>

            <div class="row h-10 my-4">
                <div
                    v-if="enabledUpdate"
                    class="col-12 d-flex justify-content-center"
                >
                    <vs-button @click="updateImageComponent">
                        Actualizar imagen
                    </vs-button>
                    <vs-button danger @click="deleteImageComponent">
                        Eliminar imagen
                    </vs-button>
                </div>
            </div>

            <div class="row d-flex flex-column flex-md-row">
                <div class="d-flex flex-column mx-2 my-3">
                    <span class="mb-2">Imagen orginal (Large)</span>
                    <img
                        loading="lazy"
                        :src="imageComponent.url"
                        alt="Imagen Large"
                        class="mw-100"
                    />
                </div>

                <fragment v-if="imageComponent.url.includes('large')">
                    <div class="d-flex flex-column mx-2 my-3">
                        <span class="mb-2">Imagen normal</span>
                        <img
                            loading="lazy"
                            :src="imageComponent.url.replace('large', 'normal')"
                            alt="Imagen Normal"
                        />
                    </div>
                    <div class="d-flex flex-column mx-2 my-3">
                        <span class="mb-2">Imagen pequeña</span>
                        <img
                            loading="lazy"
                            :src="imageComponent.url.replace('large', 'small')"
                            alt="Imagen Small"
                        />
                    </div>
                </fragment>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { validateConfirmationMessage } from "@/common/lib/transformers";

export default {
    name: "ImageDetail",
    data: () => ({
        id: 0,
        isLoading: false,
        enabledUpdate: true,
        loaderInstance: null,
        loadedAllData: false,
        imageComponent: {
            id: 0,
            alt: "",
            url: "",
            size: "",
            createdAt: "2021-10-26T00:27:59.146Z",
            updatedAt: "2021-10-26T00:27:59.146Z",
            uploaded: false
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("image:Get", "/app/images"))) return;
        const id = Number(this.$route.params.id);
        this.id = id;
        await this.getBaseImage(id);
    },
    methods: {
        ...mapActions("images", ["getImageById", "updateImage", "deleteImage"]),
        async getBaseImage(id) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                this.imageComponent = await this.getImageById({ id });
                this.loadedAllData = true;
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/images");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateImageComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.updateImage(this.imageComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async deleteImageComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas seguro de eliminar la imagen ?",
                icon: "warning",
                text: `Puedes escribir 'si' para eliminar en S3 también `,
                input: "text",
                confirmButtonColor: "#ee3f59",
                cancelButtonColor: "#253C99",
                showCancelButton: true
            });
            if (dialogResult.isConfirmed) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({ type: "circles" });
                try {
                    await this.deleteImage({
                        id: this.id,
                        definitely: validateConfirmationMessage(
                            dialogResult.value
                        )
                    });
                    this.$router.push("/app/images");
                } catch (error) {
                    this.loaderInstance.close();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async copyTo() {
            await navigator.clipboard.writeText(`${this.imageComponent.url}`);
            this.$vs.notification({
                title: "Copiado al porta papeles",
                color: "success",
                icon: "✅"
            });
        }
    }
};
</script>
